import './App.css';
import Blog from './pages/Blog';
import Blogdetail from './pages/Blogdetail';

function App() {
  return (
    <>
      <Blog />
      <Blogdetail  />
</>
  
  );
}

export default App;
