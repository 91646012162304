import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { InlineShareButtons } from 'sharethis-reactjs';
import './ShareButton.css'; // Import CSS file for styling

const ShareButton = ({ websiteTitle, websiteDescription, websiteImage }) => {
  const [refreshKey, setRefreshKey] = useState(0);

  // Handle default values or potential errors gracefully
  const title = websiteTitle || 'Website Title';
  const description = websiteDescription || 'Website Description';
  const image = websiteImage || 'https://images.unsplash.com/photo-1587614387466-0a72ca909e16?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80'; // Placeholder image with recommended width
  const url = 'https://humanxglobal.com/blog-detail/choosing-the-right-outsourcing-partner-in-nepal-a-laymans-guide';
  const error = !title || !description || !image; // Check for missing data

  return (
    <div key={refreshKey}>
      <Helmet>
        {/* Open Graph and Twitter meta tags (optional, but recommended) */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content="1200" /> {/* Recommended width */}
        <meta property="og:image:height" content="630" /> {/* Recommended height for aspect ratio 1.91:1 */}
        <meta property="og:url" content={url} /> {/* Website URL */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content="@YourTwitterHandle" /> {/* Add your Twitter handle */}
      </Helmet>

      {!error && (
        <>
          <h2>ShareThis Example</h2>
          <InlineShareButtons
            key={refreshKey} // This will force remount when the key changes
            options={{ enabled: true, networks: "twitter, facebook, pinterest" }}
          />
        </>
      )}

      {error && (
        <p className="error-message">
          Please provide website title, description, and image for sharing.
        </p>
      )}
    </div>
  );
};

export default ShareButton;
